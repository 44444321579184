import AsyncAlpine from "async-alpine";
import { Alpine, Livewire } from "../../../../../vendor/livewire/livewire/dist/livewire.esm.js";
import enquireModalAlpineStore from "./enquireModalAlpineStore.js";

/**
 * Alpine.js & Livewire setup.
 *
 * @see https://alpinejs.dev/essentials/installation#as-a-module
 * @see https://livewire.laravel.com/docs/installation#manually-bundling-livewire-and-alpine
 */
export default function setupAlpineAndLivewire() {
  window.Alpine = Alpine;

  /** @see @see https://async-alpine.dev/docs/install/#npm */
  Alpine.plugin(AsyncAlpine);

  Alpine.asyncData("ammapAsiaMapComponent", () => import("../components/ammapAsiaMapComponent.js"));
  Alpine.asyncData("amanAlumniCarouselComponent", () => import("../components/amanAlumniCarouselComponent.js"));
  Alpine.asyncData("ammapCountryMapComponent", () => import("../components/ammapCountryMapComponent.js"));
  Alpine.asyncData("bannerSlider", () => import("../components/bannerSlider.js"));
  Alpine.asyncData("carouselComponent", () => import("../components/carouselComponent.js"));
  Alpine.asyncData("enquireAmanJetExpeditionSelect", () => import("../components/enquireAmanJetExpeditionSelect.js"));
  Alpine.asyncData("enquireDestinationsSelect", () => import("../components/enquireDestinationsSelect.js"));
  Alpine.asyncData("flickityCarouselComponent", () => import("../components/flickityCarouselComponent.js"));
  Alpine.asyncData("hotelsCarouselComponent", () => import("../components/hotelsCarouselComponent.js"));
  Alpine.asyncData("itineraryMap", () => import("../components/itineraryMap.js"));
  Alpine.asyncData("itineraryStagesMapComponent", () => import("../components/itineraryStagesMapComponent.ts"));
  Alpine.asyncData("livewireTurnstileComponent", () => import("../components/livewireTurnstileComponent.js"));
  Alpine.asyncData("pageTabs", () => import("../components/pageTabs.js"));
  Alpine.asyncData("parallaxBackgroundComponent", () => import("../components/parallaxBackgroundComponent.js"));
  Alpine.asyncData("searchResultsComponent", () => import("../components/searchResultsComponent.js"));
  Alpine.asyncData("siteHeaderComponent", () => import("../components/siteHeaderComponent.js"));
  Alpine.asyncData("siteHeaderCarouselComponent", () => import("../components/siteHeaderCarouselComponent.js"));
  Alpine.asyncData("siteHeaderSearchModalComponent", () => import("../components/siteHeaderSearchModalComponent.js"));
  Alpine.asyncData("tabSlider", () => import("../components/tabSlider.js"));
  Alpine.asyncData("vimeoEmbed", () => import("../components/vimeoEmbed.js"));
  Alpine.asyncData("webinarsSelectComponent", () => import("../components/webinarsSelectComponent.js"));

  enquireModalAlpineStore();

  Alpine.magic("scrollTo", () => (selector) => {
    document.querySelector(selector)?.scrollIntoView({ behavior: "smooth" });
  });

  /** Start Livewire, which also starts Alpine. */
  Livewire.start();
}
